<template>
  <el-drawer title="标准行政区域" append-to-body :visible.sync="showProvince" :with-header="false" size="70%">
    <el-tree ref="tree" class="highlight-tree" node-key="_id" :data="data" highlight-current :props="props" :load="loadNode" lazy @node-click="handleNodeClick" :style="{ height: height + 'px', overflow: 'auto' }" />
  </el-drawer>
</template>

<script>
import request from "@/utils/request";
export default {
  props: {
    value: Number | String,
    type: String | Number,
    visible: Boolean,
  },
  watch: {
    visible: function (val) {
      this.showProvince = val;
    },
    showProvince: function (val) {
      this.$emit("update:visible", val);
    },
  },
  data() {
    return {
      showProvince: false,
      height: "",
      data: [],
      levels: ["country", "province", "city", "district"],
      root: {
        name: "全国",
        code: "",
        level: 0,
        type: "country",
        _id: this.$uuid(),
      },
      props: {
        label: "name",
        isLeaf: function (data) {
          return data.level >= 3;
        },
      },
    };
  },
  mounted() {
    this.handleNodeClick(this.root, true);
    this.height = window.innerHeight;
  },
  methods: {
    //懒加载时触发
    loadNode(node, resolve) {
      if (!node) return;
      if (node.level === 0) {
        resolve([this.root]);
      } else if (node.level === 1) {
        request({
          url: "api/regionalDict/province?page=0&size=10000",
          method: "get",
        }).then((res) => {
          this.buildNodeKey(res, 1);
          return resolve(res || []);
        });
      } else if (node.data.code && node.level > 1) {
        request({
          url:
            "api/regionalDict/parent/" +
            node.data.code +
            "?" +
            "page=0&size=10000",
          method: "get",
        }).then((res) => {
          this.buildNodeKey(res, node.level);
          return resolve(res || []);
        });
      }
    },
    buildNodeKey(source, level = 0) {
      (source || []).forEach((o) => {
        o.level = level;
        o.type = this.levels[level];
        o._id = this.$uuid();
      });
    },

    handleNodeClick(data, init = false) {
      if (data) {
        this.showProvince = false;
        this.$emit("input", data.code);
        this.$emit("update:type", data.type);
        this.$emit("change", data);
        if (init) {
          this.$emit("init");
        }
      }
    },
  },
};
</script>
