<template>
  <div class="retailMain">
    <div class="c h sb sep-t">
      <div class="no-flex padding-10">分析区域{{query.treeNodeId}}</div>
      <el-radio-group v-model="query.addressType">
        <el-radio label="area">自定义区域</el-radio>
        <el-radio label="standard">标准行政区</el-radio>
      </el-radio-group>
    </div>
    <div class="c h sep-t padding-05" @click="drawerShop">
      <div class="no-flex padding-05">{{query.addressType === 'area' ? "自定义区域" : "标准行政区"}}</div>
      <div class="flex ta-r padding-05">{{ query.addressType === 'area' ? label.area : label.region }}</div>
      <i class="el-icon-arrow-right padding-05 fc-g"></i>
    </div>
    <div class="c h sb sep-t" v-if="showOpt.showDimension">
      <div class="no-flex padding-10">分析维度</div>
      <el-radio-group v-model="query.dateGroup" size="mini">
        <el-radio label="year">年</el-radio>
        <el-radio label="month">月</el-radio>
        <el-radio label="day">日</el-radio>
      </el-radio-group>
    </div>
    <div class="c h sb sep-t" v-if="showOpt.showTimeRange">
      <div class="no-flex padding-10">时间范围</div>
      <div class="c h">
        <div class="h c" key="range-year" v-if="query.dateGroup === 'year'">
          <el-date-picker v-model="query.begDate" :editable="false" type="year" value-format="timestamp" placeholder="开始年" :clearable="false" style="width: 2.4rem;" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" :editable="false" type="year" value-format="timestamp" placeholder="结束年" :clearable="false" style="width: 2.4rem;" />
        </div>
        <div class="h c" key="range-month" v-else-if="query.dateGroup === 'month'">
          <el-date-picker v-model="query.begDate" :editable="false" type="month" value-format="timestamp" placeholder="开始月" :clearable="false" style="width: 2.4rem;" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" :editable="false" type="month" value-format="timestamp" placeholder="结束月" :clearable="false" style="width: 2.4rem;" />
        </div>
        <div class="h c" key="range-date" v-else-if="query.dateGroup === 'day'">
          <el-date-picker v-model="query.begDate" :editable="false" type="date" value-format="timestamp" placeholder="开始日" :clearable="false" style="width: 2.4rem;" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" :editable="false" type="date" value-format="timestamp" placeholder="结束日" :clearable="false" style="width: 2.4rem;" />
        </div>
      </div>
    </div>
    <div class="c h sb sep-t" v-if="showOpt.showYearonyear">
      <div class="flex padding-10">开启同比</div>
      <el-date-picker v-model="query.compareYear" type="year" placeholder="选择年" :editable="false" style="width: 2rem;" :clearable="false" v-if="query.yearOnYearShow" />
      <el-switch v-model="query.yearOnYearShow" />
    </div>
    <div class="c h sb sep-t" v-if="showOpt.showMonth">
      <div class="no-flex padding-10">开启环比</div>
      <el-switch v-model="query.compareMonth" />
    </div>
    <div class="c h sb sep-t" v-if="query.dateGroup !== 'year' && showOpt.showCompareyear">
      <div class="no-flex padding-10">同比年度</div>
      <el-input-number v-model="query.compareYear" :min="2000" :max="2099" :step="1" :precision="0" placeholder="输入年份" style="width: 2.4rem;" />
    </div>
    <template v-if="showOpt.showRangGoods">
      <div class="c h sep-t padding-05" @click="drawer.brand = true">
        <div class="no-flex padding-05">商品品牌</div>
        <div class="flex ta-r padding-05">{{ label.brand }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h sep-t padding-05" @click="drawer.series = true" v-if="query.brandId">
        <div class="no-flex padding-05">商品系列</div>
        <div class="flex ta-r padding-05">{{label.series}}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h sep-t padding-05" @click="drawer.category = true" v-if="showOpt.showGoodsType">
        <div class="no-flex padding-05">商品分类</div>
        <div class="flex ta-r padding-05">{{label.category}}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
    </template>
    <template v-if="showOpt.showCategory">
      <div class="c h sep-t padding-05" @click="drawer.category = true">
        <div class="no-flex padding-05">对比类型1</div>
        <div class="flex ta-r padding-05">{{label.category}}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>

      <div class="c h sep-t padding-05" @click="drawer.category2 = true" v-if="query.categoryId">
        <div class="no-flex padding-05">对比类型2</div>
        <div class="flex ta-r padding-05">{{label.categoryB}}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
    </template>
    <div class="c h sep-t padding-05" @click="drawer.goods = true" v-if="showOpt.showCommodity">
      <div class="no-flex padding-05">指定商品</div>
      <div class="flex ta-r padding-05">{{label.goods}}</div>
      <i class="el-icon-arrow-right padding-05 fc-g"></i>
    </div>
    <div class="c h sep-t sb" v-if="showOpt.showRank">
      <div class="no-flex padding-10">排名对象</div>
      <el-radio-group v-model="rank.multParams">
        <el-radio :label="0">区域</el-radio>
        <el-radio :label="1">经销商</el-radio>
        <el-radio :label="2">省</el-radio>
        <el-radio :label="3">市</el-radio>
      </el-radio-group>
    </div>
    <div class="c h sep-t sb" v-if="showOpt.showLevel">
      <div class="no-flex padding-10">汇总级别</div>
      <el-radio-group v-model="query.goodsGroup">
        <el-radio label="brandId">品牌结构</el-radio>
        <el-radio label="seriesId">系列结构</el-radio>
        <el-radio label="goodsCategoryId">分类结构</el-radio>
        <el-radio label="spuId">商品结构</el-radio>
      </el-radio-group>
    </div>
    <div class="c h sb sep-t" v-if="showOpt.showReported">
      <div class="no-flex padding-10">仅显示已上报</div>
      <el-switch v-model="query.reportOnly" @change="handleReportOnlyChange" size="mini" />
    </div>
    <div class="h c sb sep-t" v-if="showOpt.showRankGist">
      <div class="no-flex padding-10">排名依据</div>
      <el-radio-group v-model="query.saleValueGroup" v-if="query.reportOnly">
        <el-radio label="checkSaleNumber">销售数量</el-radio>
        <el-radio label="realCheckSumMoney">销售金额</el-radio>
        <el-radio label="checkSendNumber">发货数量</el-radio>
        <el-radio label="checkSendMoney">发货金额</el-radio>
      </el-radio-group>
      <el-radio-group v-model="query.saleValueGroup" v-else>
        <el-radio label="saleNumber">销售数量</el-radio>
        <el-radio label="realSumMoney">销售金额</el-radio>
        <el-radio label="sendNumber">发货数量</el-radio>
        <el-radio label="sendMoney">发货金额</el-radio>
      </el-radio-group>
    </div>
    <!-- 零售明细查询 -->
    <!-- <div class="padding-0-10" v-if="showOpt.showDetail">
      <div class="padding-10-0 c h">
        <div>日期</div>
        <div class="flex"></div>
        <el-date-picker v-model="query.beginTime" type="date" value-format="timestamp" placeholder="开始日" :clearable="false" style="width: 150px" />
        <el-date-picker v-model="query.endTime" type="date" value-format="timestamp" placeholder="结束日" :clearable="false" style="width: 150px" />
      </div>
      <div class="c h padding-10-0">
        <div>状态</div>
        <div class="flex"></div>
        <el-select v-if="!query.isPrepare" v-model="query.status" filterable clearable placeholder="订单状态" style="width: 150px">
          <el-option v-for="item in status" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
        <el-select v-model="query.payStatus" filterable clearable placeholder="收款状态" style="width: 150px">
          <el-option v-for="(v, k) in payStatus" :key="k" :label="v" :value="k" />
        </el-select>
      </div>
      <div class="c h">
        <el-input v-model="query.keywords" :maxlength="20" placeholder="销售单号/合同号/电话号码搜索" />
        <el-input v-model="query.goodsName" :maxlength="20" placeholder="商品ERP编码或名称" />
        <el-checkbox border v-model="query.isPrepare" @change="query.status = null">预售订单</el-checkbox>
      </div>
    </div>-->
    <!-- 自定义区域 -->
    <custom-list :visible.sync="drawer.customShop" v-model="query.area.treeNodeId" :type.sync="query.area.treeNodeType" @change="handleShopChange" @init="handleInited" />
    <!-- 标准行政区域-->
    <province-list :visible.sync="drawer.standardShop" v-model="query.standard.treeNodeId" :type.sync="query.standard.StandardAreaGroup" @change="handleShopChange" @init="handleInited" />
    <!-- 选指定商品 -->
    <sku-selector request-url="api/goods/sku" :visible.sync="drawer.goods" @change="handleGoodsSelect" />
    <!-- 商品范围 -->
    <brand-selector v-model="query.brandId" :label.sync="label.brand" :visible.sync="drawer.brand" @change="handleBrandChange" />
    <series-selector v-model="query.seriesId" :label.sync="label.series" :brand-id="query.brandId" :visible.sync="drawer.series" />
    <category-selector key="cate1" v-model="query.categoryId" :label.sync="label.category" :visible.sync="drawer.category" />
    <category-selector key="cate2" v-model="query.categoryIdB" :label.sync="label.categoryB" :visible.sync="drawer.category2" />
  </div>
</template>
  
<script>
import customList from "../area/retailCustomList";
import provinceList from "../area/provinceList";
import skuSelector from "../selector/skuSelect";
import brandSelector from "../selector/brandSelect";
import seriesSelector from "../selector/seriesSelect";
import categorySelector from "../selector/categorySelect";
export default {
  props: {
    showOpt: Object,
    query: Object,
    rank: Object,
    status: Object,
    payStatus: Object,
  },
  components: {
    customList,
    provinceList,
    skuSelector,
    brandSelector,
    seriesSelector,
    categorySelector,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      label: {
        brand: "全部",
        series: "全部",
        category: "全部",
        categoryB: "全部",
        goods: "全部",
        area: "所有",
        region: "全国",
      },
      goods: {
        brandName: null,
        seriesName: null,
        categoryName: null,
        categoryAllname: null,
      },
      drawer: {
        customShop: false,
        standardShop: false,
        goods: false,
        brand: false,
        series: false,
        category: false,
        category2: false,
      },
    };
  },
  methods: {
    handleInited() {
      this.$emit("init");
    },
    handleShopChange(data) {
      if (this.query.addressType === "area") {
        this.label.area = data ? data.name : "所有";
      } else {
        this.label.region = data ? data.name : "全国";
      }
    },
    drawerShop() {
      if (this.query.addressType == "area") {
        this.drawer.standardShop = false;
        this.drawer.customShop = true;
      } else if (this.query.addressType == "standard") {
        this.drawer.customShop = false;
        this.drawer.standardShop = true;
      }
    },
    handleGoodsSelect(res) {
      this.query.goods = res;
      if (res && res.length) {
        this.label.goods = res.map((o) => o.name).join("、");
      } else {
        this.label.goods = "全部";
      }
    },
    handleBrandChange() {
      if (this.query.hasOwnProperty("seriesId")) {
        this.query.seriesId = null;
        this.label.series = "全部";
      }
    },
    handleReportOnlyChange(val) {
      if (this.showOpt.showRankGist) {
        if (val == true) {
          this.query.saleValueGroup = "realCheckSumMoney";
        } else {
          this.query.saleValueGroup = "realSumMoney";
        }
      }
    },
  },
};
</script>